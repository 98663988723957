import classes from 'classnames';

import s from './style.module.css';

interface Props {
  className: string;
  opened: boolean;
  onClick: () => void;
}

const BurgerBtn = ({ className, opened, onClick }: Props) => {
  return (
    <div className={classes(s.burgerButton, className, opened && s.cross)} onClick={onClick}>
      <div className={s.item} />
      <div className={s.item} />
      <div className={s.item} />
    </div>
  );
};

export default BurgerBtn;
