import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { organizationSelector } from '@store/organization/selectors';

import * as S from './Disclaimer.styled';

type Props = {
  className?: string;
  hideFeesApplyLink?: boolean;
  onLinkClick?: VoidFunction;
};

export const Disclaimer: React.FC<Props> = ({ onLinkClick, ...props }) => {
  const organization = useSelector(organizationSelector);
  const fullTermsLink = useMemo(() => organization?.organizationInfo?.full_disclosure_link, [organization]);

  const text = useMemo(() => {
    if (!organization.organizationInfo) {
      return '';
    } else if (!organization.organizationInfo.disclaimer?.length) {
      return (
        'Unlike bank deposits, investments are not insured by the FDIC, ' +
        `are not deposits and are not guaranteed by ${organization.organizationInfo?.display_name}. ` +
        'Investments are subject to risks, including possible loss of principal.'
      );
    }
    return organization.organizationInfo.disclaimer;
  }, [organization.organizationInfo]);

  return (
    <S.Container className={props.className}>
      {text ? (
        <>
          {text} {!props.hideFeesApplyLink && <S.FeesLink onClick={onLinkClick}>Fees apply.</S.FeesLink>}{' '}
          {fullTermsLink && (
            <S.DisclosuresLink onClick={onLinkClick} href={fullTermsLink} target="_blank">
              Read full disclosure.
            </S.DisclosuresLink>
          )}
        </>
      ) : null}
    </S.Container>
  );
};
