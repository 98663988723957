export default function Logout() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        // eslint-disable-next-line max-len
        d="M1.90591 16C0.856096 16 0 15.1344 0 14.0658V1.93421C0 0.868807 0.852972 0 1.90591 0H7.88608C8.20478 0 8.46098 0.263179 8.46098 0.583432C8.46098 0.906857 8.20165 1.16686 7.88608 1.16686H1.90591C1.49036 1.16686 1.15292 1.50931 1.15292 1.93103V14.0658C1.15292 14.4875 1.49036 14.83 1.90591 14.83H7.88608C8.20478 14.83 8.46098 15.0931 8.46098 15.4134C8.46098 15.7337 8.20165 15.9968 7.88608 15.9968H1.90591V16Z"
      />
      <path
        // eslint-disable-next-line max-len
        d="M11.3852 12.6582C11.229 12.6582 11.0852 12.5948 10.9759 12.4838C10.754 12.2555 10.7572 11.8845 10.9821 11.6562L14.0222 8.61224H6.0705C5.75181 8.61224 5.49561 8.34906 5.49561 8.02881C5.49561 7.70538 5.75493 7.44538 6.0705 7.44538H14.0222L10.9821 4.40139C10.8728 4.29041 10.8103 4.14456 10.8103 3.98919C10.8103 3.83382 10.8696 3.68479 10.9759 3.57381C11.0852 3.46283 11.2321 3.39941 11.3852 3.39941C11.5383 3.39941 11.682 3.45966 11.7882 3.56747L15.8281 7.61026C15.9375 7.72124 16 7.87026 16 8.02563C16 8.181 15.9375 8.3332 15.8281 8.44101L11.7882 12.487C11.682 12.5979 11.5383 12.6582 11.3852 12.6582Z"
      />
    </svg>
  );
}
